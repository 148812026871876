import { MouseEvent, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'

import {
  NewtailBannerData,
  dataLayerEventPromotionClick,
  dataLayerEventPromotionView,
  dataLayerEventSimple,
  formatBannerData,
  getNewTailBannerAds,
  sendBeaconData,
  useBannerSize,
  useUser
} from '@k_frontend/core'
import { setCookie } from 'cookies-next'
import { useVisibility } from 'hooks/useVisibility'
import kernel from 'settings/kernel'

import {
  CATEGORY_NAME,
  CONTEXT,
  DATALAYER_TITLE,
  PLATAFORM,
  SIZE,
  STORAGE_NAME,
  TEN_MINUTES_IN_MILLISECONDS
} from './topBanner.constants'
import { useTopBannerContainerProps } from './topBanner.types'

function getTimeToExpireCache(): Date {
  const dateNow = new Date()
  dateNow.setHours(0, 0, 0, 0)
  dateNow.setDate(dateNow.getDate() + 1)
  return dateNow
}
export function useTopBannerContainer({
  hasClosed,
  setIsOpen,
  isOpen
}: useTopBannerContainerProps) {
  const { session, clientId } = useUser()
  const bannerRef = useRef(null)
  const isVisible = useVisibility(bannerRef, { threshold: 0.5 })
  const beaconData = {
    user_id: clientId,
    session_id: session
  }

  const bannerSize = useBannerSize(SIZE)
  const { data, isFetched } = useQuery<NewtailBannerData>(
    ['getBannerTopHome', bannerSize],
    () =>
      getNewTailBannerAds(kernel, {
        session_id: session,
        origin:
          bannerSize === SIZE.mobile ? PLATAFORM.mobile : PLATAFORM.desktop,
        context: CONTEXT,
        category_name: CATEGORY_NAME,
        placements: {
          top_home: {
            quantity: 1,
            size: bannerSize,
            types: ['banner']
          }
        }
      }),
    {
      enabled: !hasClosed && !!session,
      staleTime: TEN_MINUTES_IN_MILLISECONDS
    }
  )
  const banner = data?.top_home?.[0]

  const payloadFormatted = formatBannerData({
    name: banner?.title || banner?.campaignName,
    index: 1,
    position: 'tema_top_home_1',
    creative: banner?.creative,
    bannerId: banner?.bannerId
  })

  const onCloseBanner = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dataLayerEventSimple(DATALAYER_TITLE)
    setIsOpen(false)
    setCookie(STORAGE_NAME, 'true', {
      expires: getTimeToExpireCache()
    })
  }

  const onBannerClick = (url: string) => {
    if (url) sendBeaconData(url, beaconData)
    dataLayerEventPromotionClick(payloadFormatted)
  }

  useEffect(() => {
    if (isVisible && banner?.viewUrl) {
      sendBeaconData(banner?.viewUrl, beaconData)
    }
  }, [isVisible])

  useEffect(() => {
    if (isFetched) {
      if (!banner?.mediaUrl) setIsOpen(false)
      if (banner?.impressionUrl) {
        sendBeaconData(banner.impressionUrl, beaconData)
      }
      dataLayerEventPromotionView(payloadFormatted)
    }
  }, [isFetched])

  const hasStorageBannerClosedAndNotOpen = hasClosed || !isOpen
  const hasImgAndIsOpen = banner?.mediaUrl && isOpen

  return {
    banner,
    isFetched,
    onCloseBanner,
    onBannerClick,
    bannerRef,
    hasStorageBannerClosedAndNotOpen,
    hasImgAndIsOpen
  }
}
